import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import MentionLegale from "../components/shared/FooterPage/MentionLegale";

import ProduitItemPage from "../components/user/ParcoursAchat/ProduitItemPage";
import CguPage from "../components/shared/FooterPage/Cgu";
import ForgetPassword from "../components/auth/ForgetPassword";
import LoaderSuspense from "../components/common/LoaderSuspense";
import OurProduct from "../components/Product/OurProduct";
import PanierPage from "../components/MonCompte/MonComptePage/Panier/PanierPage";
import PolitiqueConfidentialite from "../components/front/politiqueConfidentialite/PolitiqueConfidentialite";
import Contact from "../components/front/contact/Contact";
import WhoWeAre from "../components/front/whoWeAre/WhoWeAre";
import MonCompteLayout from "../components/MonCompte/MonCompteLayout";
import ProfilPage from "../components/MonCompte/MonComptePage/ProfilPage";
import CommandePage from "../components/MonCompte/MonComptePage/Commande/CommandePage";
import CommandeDetail from "../components/MonCompte/MonComptePage/Commande/CommandeDetail";
import FavorisPage from "../components/MonCompte/MonComptePage/FavorisPage";
import { ProtectedRoutes, RedirectAuthRoute } from "./routerUtils";
import { UserType } from "../utils/api/user/user.type";
import AjoutPanier from "../components/user/ParcoursAchat/AjoutPanier";
import CGV from "../components/front/cgv/CGV";
import SuccessPaiement from "../components/MonCompte/MonComptePage/validateCommand/SuccessPaiement";
import ValidateCommand from "../components/MonCompte/MonComptePage/validateCommand/ValidateCommand";
import OrderItem from "../components/MonCompte/MonComptePage/OrderItem";
import ResetPassword from "../components/auth/ResetPassword";
import PagesError404 from "../components/shared/PagesError404";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );
const HomePage = Loader(
  React.lazy(() => import("../components/HomePage/HomePage"))
);

const ParcoursAchat = Loader(
  React.lazy(() => import("../components/user/ParcoursAchat/ParcoursAchat"))
);

export const AppRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/modifier-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/achats",
    element: <ParcoursAchat />,
  },
  {
    path: "/mentions-legales",
    element: <MentionLegale />,
  },
  {
    path: "/cgu",
    element: <CguPage />,
  },
  {
    path: "/produit/:slug",
    element: <ProduitItemPage />,
  },
  // {
  //   path: "/valider-commande",
  //   element: <ValidateCommand />,
  // },
  {
    path: "/commande-details",
    element: <OrderItem />,
  },
  {
    path: "/produits",
    element: <OurProduct />,
  },
  {
    path: "politique-de-confidentialite",
    element: <PolitiqueConfidentialite />,
  },
  {
    path: "conditions-generales-de-ventes",
    element: <CguPage />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "a-propos-de-nous",
    element: <WhoWeAre />,
  },
  {
    path: "/paiement",
    element: <SuccessPaiement />,
  },
  {
    path: "mon-compte",
    element: <MonCompteLayout />,
    children: [
      { element: <Navigate to="/mon-compte/profil" replace />, index: true },
      {
        path: "profil",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <ProfilPage />
          </ProtectedRoutes>
        ),
      },
      {
        path: "commandes",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <CommandePage />
          </ProtectedRoutes>
        ),
      },
      {
        path: "commandes/:slug",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <CommandeDetail />
          </ProtectedRoutes>
        ),
      },
      {
        path: "panier",
        element: <PanierPage />,
      },
      {
        path: "favoris",
        element: (
          <ProtectedRoutes
            userType={[UserType.admin, UserType.acheteur, UserType.vendeur]}
          >
            <FavorisPage />
          </ProtectedRoutes>
        ),
      },
    ],
  },
  {
    path: "/panier",
    element: <ValidateCommand />,
  },
  {
    path: "*",
    element: <PagesError404 />,
  },
];
